export default [
    {
        title: 'Vocal Express - Hey, Ma (Bon Iver) ',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING',
        thumbnail: {
            path: 'vocalexpress.jpg',
            alt: 'Vocal Express - Hey, Ma (Bon Iver) '
        },
        link: 'https://www.youtube.com/watch?v=6Fkmge8cbIQ',
        embedLink: 'https://www.youtube.com/embed/6Fkmge8cbIQ'
    }, 
    {
        title: 'FaMän - Tag X ',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING',
        thumbnail: {
            path: 'tagx.jpeg',
            alt: 'FaMän - Tag X '
        },
        link: 'https://www.youtube.com/watch?v=3yFWCpvxwDI',
        embedLink: 'https://www.youtube.com/embed/3yFWCpvxwDI'
    }, 
    {
        title: 'PADDLEGONIA by Tobias Schorcht ',
        task: 'CAMERA / EDITING',
        thumbnail: {
            path: 'paddlegonia.jpg',
            alt: 'PADDLEGONIA by Tobias Schorcht '
        },
        link: 'https://www.youtube.com/watch?v=25TjCwDJ9mE',
        embedLink: 'https://www.youtube.com/embed/25TjCwDJ9mE'
    },
    {
        title: 'DigiSozial - Erklärfilm ',
        task: 'ILLUSTRATION / ANIMATION / EDITING',
        thumbnail: {
            path: 'digisozial.jpeg',
            alt: 'DigiSozial '
        },
        link: 'https://www.youtube.com/watch?v=ZSiQW0HMZzs',
        embedLink: 'https://www.youtube.com/embed/ZSiQW0HMZzs'
    }, 
    {
        title: 'GOLDHARDT - Insel',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'goldhardti.jpg',
            alt: 'GOLDHARDT - Insel '
        },
        link: 'https://www.youtube.com/watch?v=nj5ODmyZCbo',
        embedLink: 'https://www.youtube.com/embed/nj5ODmyZCbo'
    },
    {
        title: 'Klausn - Bettbezug',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'klausnbbz.jpg',
            alt: 'Klausn - Bettbezug '
        },
        link: 'https://www.youtube.com/watch?v=MY2D5tfbkRM',
        embedLink: 'https://www.youtube.com/embed/MY2D5tfbkRM'
    },
    {
        title: 'Amprion X diva-e Case Study',
        task: 'DIRECTOR / ANIMATION / EDITING',
        thumbnail: {
            path: 'amprion.jpg',
            alt: 'Amprion X diva-e Case Study'
        },
        link: 'https://www.youtube.com/watch?v=hEs7xjvZi90',
        embedLink: 'https://www.youtube.com/embed/hEs7xjvZi90'
    },
    {
        title: 'Weltsichten Diafestival 2018 Trailer',
        task: 'CAMERA / EDITING / GRADING',
        thumbnail: {
            path: 'diafestival18.jpg',
            alt: 'Weltsichten Diafestival 2018 Trailer'
        },
        link: 'https://vimeo.com/282927254',
        embedLink: 'https://player.vimeo.com/video/282927254?h=6b6326015f&color=E0D7A4&title=0&byline=0&portrait=0'
    },
    {
        title: 'GOLDHARDT - Gehn zu Zweit',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'goldhardtgzz.jpg',
            alt: 'GOLDHARDT - Gehn zu Zweit '
        },
        link: 'https://www.youtube.com/watch?v=2jLraaiCYzI',
        embedLink: 'https://www.youtube.com/embed/2jLraaiCYzI'
    },
    {
        title: 'Dr. med Juliane Waas - Seminar',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'waas.jpg',
            alt: 'Dr. med Juliane Waas - Seminar'
        },
    },
    {
        title: 'Axel Brümmer "Die Dschunke" Multivision',
        task: 'CAMERA / EDITING / GRADING',
        thumbnail: {
            path: 'dschunke.jpg',
            alt: 'Axel Brümmer "Die Dschunke" Multivision'
        },
    },
    {
        title: 'Klausn - Backstage',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'klausnbs.jpg',
            alt: 'Klausn - Backstage '
        },
        link: 'https://www.youtube.com/watch?v=_jYhbMVul0A',
        embedLink: 'https://www.youtube.com/embed/_jYhbMVul0A'
    },
    {
        title: 'Thomas Sbampato - Namibia Botswana',
        task: 'EDITING',
        thumbnail: {
            path: 'sbampatonb.jpg',
            alt: 'Thomas Sbampato - Namibia Botswana'
        },
        link: 'https://www.youtube.com/watch?v=CocTPuLG4NI',
        embedLink: 'https://www.youtube.com/embed/CocTPuLG4NI'
    },
    {
        title: 'Thomas Sbampato - Show Trailer',
        task: 'CAMERA / EDITING / GRADING',
        thumbnail: {
            path: 'sbampatofw.jpg',
            alt: 'Thomas Sbampato - Show Trailer'
        },
        link: 'https://www.youtube.com/watch?v=kdjBHhkgc0s',
        embedLink: 'https://www.youtube.com/embed/kdjBHhkgc0s'
    },
    {
        title: 'Klausn - Kling Glöckchen',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'klausnkg.jpg',
            alt: 'Klausn - Kling Glöckchen '
        },
        link: 'https://www.youtube.com/watch?v=H7X7bPZzVAs',
        embedLink: 'https://www.youtube.com/embed/H7X7bPZzVAs'
    },
    {
        title: 'SenseFate & Richmän - Willkommen in der Basis',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'famaenwidb.jpg',
            alt: 'SenseFate & Richmän - Willkommen in der Basis'
        },
        link: 'https://www.youtube.com/watch?v=2quioQh9dPI',
        embedLink: 'https://www.youtube.com/embed/2quioQh9dPI'
    },
    {
        title: 'Sag jetzt nichts - Kurzfilm',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'shortsjn.jpg',
            alt: 'Sag jetzt nichts - Kurzfilm'
        },
        link: 'https://vimeo.com/282925095',
        embedLink: 'https://player.vimeo.com/video/282925095?h=ba468948a1&color=E0D7A4&title=0&byline=0&portrait=0'
    },
    {
        title: 'Timothy Ault & Kelvin Colt - Tourblog',
        task: 'EDITING / GRADING',
        thumbnail: {
            path: 'auldcolt.jpg',
            alt: 'Timothy Ault & Kelvin Colt (Tourblog)'
        },
    },
    {
        title: 'SenseFate & Richmän - Bis es jeder weiß ',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'famaenbejw.jpg',
            alt: 'SenseFate & Richmän - Bis es jeder weiß '
        },
        link: 'https://www.youtube.com/watch?v=zuICQDJq-sQ',
        embedLink: 'https://www.youtube.com/embed/zuICQDJq-sQ'
    },
    {
        title: 'Anøki - Alles ',
        task: 'CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'anokia.jpg',
            alt: 'Anøki - Alles '
        },
        link: 'https://vimeo.com/166394054',
        embedLink: 'https://player.vimeo.com/video/166394054?h=c6061e1dd3&color=E0D7A4&title=0&byline=0&portrait=0'
    },
    {
        title: 'Amne$ie - Zweitausendheute ',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING / GRADING',
        thumbnail: {
            path: 'amnezzth.jpg',
            alt: 'Amne$ie - Zweitausendheute '
        },
        link: 'https://www.youtube.com/watch?v=EzORY1zNYYQ',
        embedLink: 'https://www.youtube.com/embed/EzORY1zNYYQ'
    },
    {
        title: 'Optics Balzers Exhibition Trailer',
        task: 'ANIMATION / EDITING',
        thumbnail: {
            path: 'opticsbalzers.jpg',
            alt: 'Optics Balzers Exhibition Trailer'
        },
    },
    {
        title: 'Amne$ie - Weil Wir Stars Sind ',
        task: 'DIRECTOR / CAMERA / LIGHT / EDITING',
        thumbnail: {
            path: 'amnezwwss.jpg',
            alt: 'Amne$ie - Weil Wir Stars Sind '
        },
        link: 'https://www.youtube.com/watch?v=6MSqxKRcZy0',
        embedLink: 'https://www.youtube.com/embed/6MSqxKRcZy0'
    },   
] 