<template>
  <div id="app">
    <div class="main-container">
      <Navbar/>
      <router-view></router-view>
    </div>
    <Footer/>
  </div>
  
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  mounted() {
    document.title = 'Tim Schorcht - Video Production'
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
#app {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #F5F5F5 !important;
  background:#111111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (min-width: 2000px) {
  .main-container {
    max-width: 2000px;
  }
}

.main-container {
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-weight: 700;
  text-transform: uppercase !important;
  color: #F5F5F5 !important;
  float: left !important;
}

h3 {
  font-weight: 400;
}

.default-container-mt {
  margin-top: 170px;
}

a {
  color: #F5F5F5 !important;
}


button {
  border-color: #F5F5F5 !important;
  color: #F5F5F5 !important;
}



</style>
