/* Based on https://github.com/appleple/react-modal-video/ */

<template>
  <iframe
    :src="link" 
    title="Video Player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
  </iframe>
</template>

<script>
  export default {
    props: {
      link: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>
  iframe {
    height: 100%;
    width: 100%;
  }
</style>