import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueRouter from 'vue-router'

// View / Components
import Home from './components/Home.vue'
import About from './components/About.vue'
import Contact from './components/Contact.vue'
import Imprint from './components/Imprint.vue'
import VModal from 'vue-js-modal'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VModal)

const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About }, 
    { path: '/contact', component: Contact },
    { path: '/imprint', component: Imprint },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')