<template>
  <div> 
    <div class="frontpic position-relative">
      <img class="img-fluid flip-img" src="@/assets/img/banner.jpg" alt="Banner">
        <a class="nav-link arrow d-none d-xl-block" href="#mywork">
          <img class="img-fluid" src="@/assets/img/arrow_down.png" alt="Arrow">
      </a>
    </div>

    <div class="portfolio container mt-4">
        <div class="row"> 
        <div class="col-12 my-4">
          <h1 id='mywork'>// my work</h1>
        </div>    
        <project-tile 
          class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 my-2"
          v-for="project in projects"
          :key="project"
          :title="project.title"
          :task="project.task"
          :thumbnail="project.thumbnail"
          :link="project.link"
          :embedLink="project.embedLink"
        ></project-tile>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTile from '@/components/ProjectTile'
import projects from '@/content/projects.js'

export default {
  name: 'Home',
  components: { ProjectTile },
  data() {
    return {
      projects: projects
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.flip-img {
      transform: scaleX(-1);
}

.videos {
display: flex;
flex-wrap: wrap;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.arrow {
    float: left;
    position: absolute;
    right: 48%;
    bottom: 20%;
    z-index: 1000;
}

</style>
