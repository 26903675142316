<template>
    <div class="container default-container-mt">  
        <div class="row">
            <div class="col-12">
                <h1>// about me</h1>
            </div>  
            <div class="col-lg-8 col-12 mt-3">
                <img src="@/assets/img/about.jpg" alt="about" class="img-fluid">
            </div>
            <div class="col-lg-4 col-12">
                <p class="about-text">
                    Hi, I'm Tim.
                    I've been making videos since 2013.
                    I've seen how they help businesses,
                    artists and products to success.
                    Let me help you bring your vision to life!
                </p>
            </div>
            <div class="col-12 mt-5">
                <h1>// my clients</h1>
            </div>
            <div class="col-12 mt-3 mb-5">
                <div class="row">
                    <div v-for="image in images" :key="image.src" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 my-3">
                        <img class="img-fluid" :src="require(`@/assets/img/${image.src}`)" :alt="image.alt">
                    </div>
                </div>
            </div>
        </div>              
    </div>
</template>

<script>

export default {
    name: 'About',
    components: {  },
    data() {
      return { 
          images: [
              {
                  src: 'jenoptik.png',
                  alt: 'jenoptik'
              },
              {
                  src: 'diva-e.jpg',
                  alt: 'diva-e'
              },
              {
                  src: 'styleheads.png',
                  alt: 'styleheads'
              },
              {
                  src: 'balzerslogo.jpg',
                  alt: 'balzerslogo'
              },
              {
                  src: 'klinikum.png',
                  alt: 'klinikum'
              },
              {
                  src: 'weltsichten.jpg',
                  alt: 'weltsichten'
              },
              {
                  src: 'zukunftsgestalter.png',
                  alt: 'zukunftsgestalter'
              }
          ]
      }
    },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.about-text {
    font-size: 32px;
    font-weight: 100;
    text-align: left;
}
</style>
