<template>
  <!-- <div class= "videos">
    <div class= "thumbnail" div id="wrapper">
      <img :src="require(`@/assets/img/${this.thumbnail.path}`)" :alt="thumbnail.alt">
    </div>
    <p class="projektname"> {{ title }} </p>
    <p class="aufgabe"> {{ task }}</p>
  </div>
  -->
  
  <div>
    <div>
      <div class="thumbnail" @click="showModal()">
        <img class="img-fluid" :src="require(`@/assets/img/${this.thumbnail.path}`)" :alt="thumbnail.alt">
      </div>
      <div class="project-caption">
        <p class="project-title"> {{ title }} </p>
        <p class="project-task"> {{ task }}</p>
      </div>
    </div>
  </div>
</template>


<script>
  import Video from '@/components/Video.vue'

  export default {
    name: 'ProjectTile',
    props: {
      title: {
        type: String,
        required: true
      },

      task: {
        type: String,
        required: true  
      },

      thumbnail: {
        path: {
         type: String,
         required: true
        },
        alt: {
          type: String,
          required: true     
        }
      },
      link: {
        type: String,
        required: false
      },
      embedLink: {
        type: String,
        required: false
      }
    },
    methods: {
      showModal() {
        if(this.embedLink) {
          this.$modal.show( 
            Video, 
            { link: this.embedLink },
            { 
              scrollable: false,
              classes: 'd-inline-block video-modal',
              width: 850,
              height: 480,
              styles: 'border-radius: 20px; background-color: black;'
            }
          )
        }
      }
    }
  }
</script>

<style>
  
  .thumbnail:hover {
  position: relative;
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter:    grayscale(100%);
  -ms-filter:     grayscale(100%);
  -o-filter:      grayscale(100%);
  transform: scale(1.01);
  cursor: pointer;
}

  .project-caption {
    text-align: left;
  }

  .project-title {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 0.1rem;
  }

  .project-task {
    font-size: 16px;
    font-weight: 700;
  }

  @media (max-width: 850px) {
    .video-modal {
      width: 100vw !important;
      left: 0 !important;
      right: 0 !important;
    }
  }
  
</style>>
